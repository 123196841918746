import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class EntitySummary {
    @keyColumn() id: string;
    @column() parentId: string;
    @column() email: string;
    @column() name: string;
    @column() type: string;
    @column() address1: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() homeNumber: string;
    @column() mobileNumber: string;
    @column() workNumber: string;
    @column() createdDate?: Date;
    @column() liabilityInsuranceExpirationDate: Date;
    @column() rocLicenseExpirationDate: Date;
    @column() workmansCompExpirationDate: Date;
    @column() servicerAgreementExpirationDate: Date;
    @column() additionalInsuranceExpirationDate: Date;
    @column() dbaName: string;
    @column() legalName: string;
    @column() licenseNumber: string;
    @column() loginId: string;
    @column() inactive: boolean;
    @column() allowTimeSlotSelection: boolean;
    @column() website: string;
    @column() agentEmailIndex?: number;
    @column() apiUserName: string;
    @column() apiPassword: string;
    @column() apiBaseUrl: string;
    @column() dispatchActivatedDate?: Date;
    @column() assignedEmployeeId: string;
    @column() optIn: boolean;
    @column() assignedEmployeeName: string;
    @column() createdByName: string;

    other: any;
    imageUrl: string;

}